import Swiper from 'swiper/bundle';
import {Navigation} from 'swiper/modules'

require(['_common/js/app.js']);


document.querySelectorAll('.swiper').forEach((item, index) => {
    item.id = `swiper-${index}`;
    new Swiper(`#${item.id}`, {
        modules: [Navigation],
        slidesPerView: "auto",
        spaceBetween: 18,
        grid: {
            rows: 2,
        },
        breakpoints:  {
            715: {

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            }
        },


    })
})

document.querySelectorAll(`img[loading="lazy"]`).forEach(item => {
    item.addEventListener('error', e => {
        e.target.src = e.target.dataset.src || 'error'
    }, {once: true})
    item.src = item.src
})

$(document).ready(function () {
    $('button.zvukDlButton, button.zvukPlayButton').on('click', function(event) {
        let code = $(this).data('code');
        if (code) {
            event.stopPropagation();
            event.preventDefault();
            window.open('/zvuk_transfer?q=' + code, '_blank');
        }
    });
});